import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { Col, Nav, Navbar, Row, NavDropdown } from "react-bootstrap";
import Logo from "../../assets/img/lottie/apd-logo.json";
import Lottie from "react-lottie";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

function NavbarTop(props) {
  const [menuOpen, setMenuOpen] = useState(false);
  const { t } = useTranslation("common");

  const [headerLanguageToggle, setHeaderLanguageToggle] = React.useState(false);
  const [stateOfLang, setStateOfLang] = React.useState(
    sessionStorage.getItem("language") || "gr"
  );

  const changeLangHandler = (val) => {
    i18next.changeLanguage(val);
    setStateOfLang(val);
    sessionStorage.setItem("language", val);
  };

  const headerLanguageDropdown = () => {
    setHeaderLanguageToggle(!headerLanguageToggle);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Logo,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const handleClose = () => setMenuOpen(false);
  return (
    <>
      <div className="social-container">
        <p className="social-text">{t("socialMediaBarText")}</p>
        <ul className="social-icons">
          <li>
            <a
              href="https://www.instagram.com/apdmaster_/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-instagram"></i>
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/apdmaster-at-aristotle-university-medical-school/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-linkedin"></i>
            </a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/profile.php?id=100092029584366"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-facebook"></i>
            </a>
          </li>
        </ul>
      </div>
      <Container className="container-without-padding" fluid>
        <Row className="row-aligned-center">
          <Col xl={2} xs={4}>
            <div className="d-flex justify-content-center">
              <Link
                className="nav-link"
                to="/"
                onClick={() => (isMobile ? setMenuOpen(!menuOpen) : null)}
              >
                <Lottie options={defaultOptions} width="70px" />
              </Link>
              {/* <img style={{ width: "70px" }} alt="Logo" src={Logo} /> */}
            </div>
          </Col>
          <Col className="p-0" xl={10} xs={8} md={8}>
            <Navbar className="custom-navbar-styling" expand="lg">
              <Container className="justify-content-end" fluid>
                <Navbar.Toggle
                  aria-controls={`offcanvasNavbar-expand-sm`}
                  onClick={() => setMenuOpen(!menuOpen)}
                />
                <Navbar.Offcanvas
                  id={`offcanvasNavbar-expand-xl`}
                  aria-labelledby={`offcanvasNavbarLabel-expand-sm`}
                  placement="end"
                  restoreFocus={false}
                  show={menuOpen}
                  onHide={handleClose}
                >
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title id={`offcanvasNavbarLabel-expand-sm`}>
                      <div className="d-flex justify-content-center">
                        <Link
                          className="nav-link"
                          to="/"
                          onClick={() =>
                            isMobile ? setMenuOpen(!menuOpen) : null
                          }
                        >
                          <Lottie options={defaultOptions} width="70px" />
                        </Link>
                        {/* <img style={{ width: "70px" }} alt="Logo" src={Logo} /> */}
                      </div>
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Nav className=" flex-grow-1 justify-content-evenly pe-3">
                      <NavDropdown
                        title={t("studies")}
                        id={`offcanvasNavbarDropdown-expand-xl`}
                      >
                        <NavDropdown.Item>
                          <Link
                            className="nav-link color-black"
                            to="/general-info"
                            onClick={() =>
                              isMobile ? setMenuOpen(!menuOpen) : null
                            }
                          >
                            {t("general")}
                          </Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                          <Link
                            className="nav-link color-black"
                            to="/carriers"
                            onClick={() =>
                              isMobile ? setMenuOpen(!menuOpen) : null
                            }
                          >
                            {t("carriers")}
                          </Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                          <Link
                            className="nav-link color-black"
                            to="/courses"
                            onClick={() =>
                              isMobile ? setMenuOpen(!menuOpen) : null
                            }
                          >
                            {t("courses")}
                          </Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                          <Link
                            className="nav-link color-black"
                            to="/application-form"
                            onClick={() =>
                              isMobile ? setMenuOpen(!menuOpen) : null
                            }
                          >
                            {t("application")}
                          </Link>
                        </NavDropdown.Item>
                      </NavDropdown>
                      <Link
                        className="nav-link"
                        to="/faculty"
                        onClick={() =>
                          isMobile ? setMenuOpen(!menuOpen) : null
                        }
                      >
                        {t("faculty")}
                      </Link>
                      <Link
                        className="nav-link"
                        to="/equipment"
                        onClick={() =>
                          isMobile ? setMenuOpen(!menuOpen) : null
                        }
                      >
                        {t("equipment")}
                      </Link>
                      <Link
                        className="nav-link"
                        to="/faq"
                        onClick={() =>
                          isMobile ? setMenuOpen(!menuOpen) : null
                        }
                      >
                        {t("faq")}
                      </Link>
                      <Link
                        className="nav-link"
                        to="/contact"
                        onClick={() =>
                          isMobile ? setMenuOpen(!menuOpen) : null
                        }
                      >
                        {t("contactTitle")}
                      </Link>
                      <NavDropdown
                        onClick={headerLanguageDropdown}
                        title={stateOfLang === "en" ? "English" : "Ελληνικά"}
                        id={`offcanvasNavbarDropdown-expand-xl`}
                      >
                        <NavDropdown.Item>
                          <span className="langSelected">
                            <div
                              className="langText"
                              onClick={() => {
                                changeLangHandler("gr");
                                setStateOfLang("gr");
                              }}
                            >
                              {t("langGr")}
                            </div>
                            <i
                              className={
                                headerLanguageToggle
                                  ? "symmetry-icon-arrow-up"
                                  : "symmetry-icon-arrow-down"
                              }
                            ></i>
                          </span>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                          <span
                            className={
                              headerLanguageToggle
                                ? "langInactive langText-visible"
                                : "langInactive"
                            }
                          >
                            <div
                              className="langText"
                              onClick={() => {
                                changeLangHandler("en");
                                setStateOfLang("en");
                              }}
                            >
                              English
                            </div>
                          </span>
                        </NavDropdown.Item>
                      </NavDropdown>
                    </Nav>
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </Container>
            </Navbar>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default NavbarTop;
